import React from "react";
import arbusto from '../../assets/image/34.jpeg'
import arbusto2 from '../../assets/image/paleta.png'

const Custom = () => {


    return (
        <div className="bg-[#060F29]">
         
            <div className='w-4/5 mx-auto mt-10 py-10 border-t-[10px] border-[#060F29]  md:flex flex-wrap justify-center space-x-24 text-white'>
            <div className='md:flex md:leading-loose'>
                <ul className="text-center space-y-5 text-[20px] ">
                    <li className="text-[#CAAB57] text-[30px] md:pb-2 border-t border-[#e6e6e64d]">Type of Material</li>
                    <li className="md:py-1 border-b border-[#e6e6e64d]">Aluminum</li>
                </ul>
            </div>
            <div className='md:flex flex-col md:leading-loose border-b border-t border-[#e6e6e64d]'>
                <ul className="text-center space-y-5 text-[20px]">
                    <li className="text-[#CAAB57] text-[30px] md:pb-2">Type of Cut</li>
                    <li className="md:py-1">Miter cut</li>
                    <li className="md:pb-1">Bevel cut</li>
                    <li className="md:pb-1">Scallop cut</li>
                    <li className="md:pb-1">Corbel cut</li>
                </ul>
                <div className="md:h-[300px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url("${arbusto}")` }} ></div>
            </div>
            <div className='md:flex flex-col md:leading-loose'>
                <ul className="text-center space-y-5 text-[20px] border-b border-[#e6e6e64d]">
                    <li className="text-[#CAAB57] text-[30px] md:pb-2 border-t border-[#e6e6e64d]">Patio colors</li>
                    <li className="md:py-1">White</li>
                    <li className="md:pb-1">Pewter</li>
                    <li className="md:pb-1">Sandawood</li>
                    <li className="md:pb-1">Driftwood</li>
                    <li className="md:pb-1">Rosewood</li>
                    <li className="md:pb-1">Beechwood</li>
                    <li className="md:pb-1">Maplewood</li>
                    <li className="md:pb-1">Siennawood</li>
                </ul>
                <div className="mt-4 md:pt-4 md:h-[280px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url("${arbusto2}")` }} ></div>
            </div>
            <div className='md:flex md:leading-loose'>
                <ul className="text-center space-y-5 text-[20px]">
                    <li className="text-[#CAAB57] text-[30px] md:pb-2 border-t border-[#e6e6e64d]">Patio sizes</li>
                    <li className="md:py-1 border-b border-[#e6e6e64d]">Custom</li>
                </ul>
            </div>
        </div>
        </div>

    )
}


export default Custom;